import clsx from 'clsx';

import { classes } from './styles';
import type { IconProps } from './types';

export const ArrowTopRightIcon = ({ className = '' }: IconProps) => (
  <svg
    width="100%"
    height="100%"
    className={clsx(classes.outline, className)}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.50003 14.1666L14.8334 5.83325M14.8334 5.83325H6.50003M14.8334 5.83325V14.1666"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
