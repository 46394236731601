import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { Heading } from '../Heading';
import { classes } from './styles';
import type { AlertProps } from './types';

export const Alert = ({
  show,
  text,
  variant,
  onCloseFn = () => {},
}: AlertProps) => {
  const [showAlert, setShowAlert] = useState<boolean>(show);

  useEffect(() => {
    setShowAlert(true);
    const timeOutAlert = setTimeout(() => {
      setShowAlert(false);
      onCloseFn();
    }, 6000);
    return () => {
      clearTimeout(timeOutAlert);
    };
  }, [show]);

  return (
    <div
      className={clsx(
        classes.default,
        !showAlert && 'hidden',
        classes.background[variant]
      )}
    >
      <Heading
        level={6}
        color="noStyle"
        className={clsx('text-center', classes.color[variant])}
      >
        {text}
      </Heading>
    </div>
  );
};
