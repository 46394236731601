import clsx from 'clsx';
import React from 'react';

import { LeftArrowIcon, RightArrowIcon } from '@/components/Icons';
import { Container } from '@/layouts/Container';

import { classes } from '../styles';
import type { PaginationPropsSet } from '../types';

export const PaginationWeb = ({
  currentPage,
  onPageChange,
  paginationRange,
  onNext,
  onPrevious,
}: PaginationPropsSet) => {
  return (
    <Container>
      <div className="border-t border-solid border-primary-200 pt-5">
        <ul className="flex justify-between">
          <li
            onClick={onPrevious}
            className={clsx(
              classes.default,
              classes.deactive,
              (currentPage === 0 || currentPage === 1) && classes.deactiveButton
            )}
          >
            <div className="flex items-center gap-3">
              <LeftArrowIcon className="w-3" />
              <h2>Previous</h2>
            </div>
          </li>
          <div className="flex gap-3">
            {paginationRange &&
              paginationRange.map((pageNumber) => (
                <li
                  key={pageNumber}
                  className={clsx(
                    classes.default,
                    currentPage !== pageNumber && classes.deactive,
                    currentPage === pageNumber && classes.active
                  )}
                  onClick={() => onPageChange(Number(pageNumber))}
                >
                  {pageNumber}
                </li>
              ))}
          </div>
          <li
            onClick={onNext}
            className={clsx(
              classes.default,
              classes.deactive,
              paginationRange &&
                paginationRange.length === currentPage &&
                classes.deactiveButton
            )}
          >
            <div className="flex items-center gap-3">
              <h2>Next</h2>
              <RightArrowIcon className="w-3" />
            </div>
          </li>
        </ul>
      </div>
    </Container>
  );
};
