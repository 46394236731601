import {
  EXCEL_FILE_TYPES,
  WORD_FILE_TYPES,
  ZIP_FILE_TYPE,
} from '@/constants/fileTypes';

export const iconLink = (fileType: string) => {
  if (EXCEL_FILE_TYPES.includes(fileType)) {
    return '/assets/icon-images/excel-icon.png';
  }
  if (WORD_FILE_TYPES.includes(fileType)) {
    return '/assets/icon-images/word-icon.png';
  }
  if (fileType.includes(ZIP_FILE_TYPE)) {
    return '/assets/icon-images/zip-icon.png';
  }
  return '/assets/icon-images/pdf-icon.png';
};
