import React from 'react';

import { LeftArrowIcon, RightArrowIcon } from '@/components/Icons';
import { Typography } from '@/components/Typography';
import { Container } from '@/layouts/Container';

import type { PaginationProps } from '../types';

export const PaginationMobile = ({
  onNext,
  onPrevious,
  currentPage,
  pageSize,
  totalCount,
}: { onNext: () => void; onPrevious: () => void } & PaginationProps) => {
  return (
    <Container>
      <div className="flex items-center justify-between border-t border-solid border-primary-200 pt-5">
        <div onClick={onPrevious}>
          <LeftArrowIcon className="w-3 text-primary-700" />
        </div>
        <Typography size="medium" variant="mediumDark">
          Page {currentPage} of {Math.ceil(totalCount / pageSize)}
        </Typography>
        <div onClick={onNext}>
          <RightArrowIcon className="w-3 text-primary-700" />
        </div>
      </div>
    </Container>
  );
};
