import clsx from 'clsx';

import { classes } from './styles';
import type { IconProps } from './types';

export const BadgeIcon = ({ className = '' }: IconProps) => (
  <svg
    className={clsx(classes.outline, className)}
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_387_55307)">
      <path
        d="M19.3316 7.99609C18.6422 7.07188 18.1805 5.97305 17.9957 4.81914C17.882 4.10977 17.5527 3.46563 17.0434 2.95625C16.5344 2.44727 15.8902 2.11797 15.1809 2.0043C14.027 1.81953 12.9281 1.35781 12.0039 0.668359C11.4176 0.23125 10.7246 0 10 0C9.27539 0 8.58242 0.23125 7.99609 0.668359C7.07188 1.35781 5.97305 1.81953 4.81914 2.0043C4.10977 2.11797 3.46563 2.44727 2.95625 2.95664C2.44727 3.46563 2.11797 4.10977 2.0043 4.81914C1.81953 5.97305 1.35781 7.07188 0.668359 7.99609C0.23125 8.58242 0 9.27539 0 10C0 10.7246 0.23125 11.4176 0.668359 12.0039C1.35781 12.9281 1.81953 14.027 2.0043 15.1809C2.11797 15.8902 2.44727 16.534 2.95664 17.0438C3.46602 17.5527 4.10977 17.882 4.81914 17.9957C5.97305 18.1805 7.07188 18.6422 7.99609 19.3316C8.58242 19.7688 9.27539 20 10 20C10.7246 20 11.4176 19.7688 12.0039 19.3316C12.9281 18.6422 14.027 18.1805 15.1809 17.9957C15.8902 17.882 16.5344 17.5527 17.0438 17.0434C17.5527 16.5344 17.882 15.8902 17.9957 15.1809C18.0883 14.6039 18.2496 14.0406 18.4742 13.5063C18.6992 12.9719 18.9867 12.466 19.3316 12.0039C19.7688 11.4176 20 10.7246 20 10C20 9.27539 19.7688 8.58242 19.3316 7.99609Z"
        fill="#FFF239"
      />
      <path
        d="M20 10C20 10.7246 19.7688 11.4176 19.3316 12.0039C18.9867 12.466 18.6992 12.9719 18.4742 13.5063C18.2496 14.0406 18.0883 14.6039 17.9957 15.1809C17.882 15.8902 17.5527 16.5344 17.0438 17.0434C16.5344 17.5527 15.8902 17.882 15.1809 17.9957C14.027 18.1805 12.9281 18.6422 12.0039 19.3316C11.4176 19.7688 10.7246 20 10 20V0C10.7246 0 11.4176 0.23125 12.0039 0.668359C12.9281 1.35781 14.027 1.81953 15.1809 2.0043C15.8902 2.11797 16.5344 2.44727 17.0434 2.95625C17.5527 3.46563 17.882 4.10977 17.9957 4.81914C18.1805 5.97305 18.6422 7.07188 19.3316 7.99609C19.7688 8.58242 20 9.27539 20 10Z"
        fill="#FFD600"
      />
      <path
        d="M10 2.85156C6.0582 2.85156 2.85156 6.0582 2.85156 10C2.85156 13.9418 6.0582 17.1484 10 17.1484C13.9418 17.1484 17.1484 13.9418 17.1484 10C17.1484 6.0582 13.9418 2.85156 10 2.85156Z"
        fill="#FFD600"
      />
      <path
        d="M17.1484 10C17.1484 13.9418 13.9418 17.1484 10 17.1484V2.85156C13.9418 2.85156 17.1484 6.0582 17.1484 10Z"
        fill="#FFB229"
      />
      <path
        d="M13.9893 8.55313C13.9061 8.31992 13.685 8.16406 13.4374 8.16406H11.3194L10.5507 6.04961C10.4663 5.81836 10.2464 5.66406 9.99989 5.66406C9.75341 5.66406 9.53348 5.81836 9.44911 6.04961L8.68036 8.16406H6.56239C6.31473 8.16406 6.09364 8.31992 6.01044 8.55313C5.92723 8.78633 5.9995 9.04648 6.1913 9.20352L7.81825 10.5348L6.94911 12.9246C6.86395 13.1598 6.937 13.423 7.13114 13.5805C7.32567 13.7379 7.59833 13.7543 7.81044 13.6219L9.99989 12.2535L12.1893 13.6219C12.2847 13.6816 12.3925 13.7109 12.4999 13.7109C12.6311 13.7109 12.7616 13.6668 12.8686 13.5805C13.0628 13.423 13.1358 13.1598 13.0507 12.9246L12.1815 10.5348L13.8085 9.20352C14.0003 9.04648 14.0725 8.78633 13.9893 8.55313Z"
        fill="#FFF239"
      />
      <path
        d="M13.8086 9.20352L12.1816 10.5348L13.0508 12.9246C13.1359 13.1598 13.0629 13.423 12.8688 13.5805C12.7617 13.6668 12.6313 13.7109 12.5 13.7109C12.3926 13.7109 12.2848 13.6816 12.1895 13.6219L10 12.2535V5.66406C10.2465 5.66406 10.4664 5.81836 10.5508 6.04961L11.3195 8.16406H13.4375C13.6852 8.16406 13.9063 8.31992 13.9895 8.55313C14.0727 8.78633 14.0004 9.04648 13.8086 9.20352Z"
        fill="#FFD600"
      />
    </g>
    <defs>
      <clipPath id="clip0_387_55307">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
