import Link from 'next/link';

import { Heading, Typography } from '@/components';
import { NewsletterForm } from '@/components/NewsletterForm';
import type { FooterType } from '@/types/footer';
import getImage from '@/utils/getImage';

import { Container } from '../../Container';

export const FooterMobile = ({
  footer,
}: {
  footer: FooterType['attributes'];
}) => {
  return (
    <section className="bg-footer py-6 pt-mobileContainer">
      <Container>
        <div className="grid grid-cols-1 gap-6">
          <div>
            <div className="w-[135px]">
              <img
                src={'/images/logo.png'}
                alt="logo"
                className="h-full w-full"
              />
            </div>
            <Typography size="medium" variant="lightDark" className="my-6">
              {footer.description}
            </Typography>
          </div>
          <div className="grid grid-cols-1 gap-default">
            <div>
              <Heading className="mb-6" level={5}>
                Navigations
              </Heading>
              <div>
                <ul className="flex flex-col space-y-4">
                  {footer.navigationPage &&
                    footer.navigationPage.length > 0 &&
                    footer.navigationPage.map((item) => (
                      <li key={item.id}>
                        {item.href === '/' || item.href === '/about' ? (
                          <a
                            href={item.href}
                            className="text-lg font-semibold text-primary-700"
                          >
                            {item.displayName}
                          </a>
                        ) : (
                          <Link
                            href={item.href}
                            className="text-lg font-semibold text-primary-700"
                          >
                            {item.displayName}
                          </Link>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div>
              <Heading className="mb-6" level={5}>
                Our Services
              </Heading>
              <div>
                <ul className="flex flex-col space-y-4">
                  {footer.navigationServices &&
                    footer.navigationServices.length > 0 &&
                    footer.navigationServices.map((service) => (
                      <li key={service.id}>
                        <Link
                          href={service.href}
                          className="text-lg font-semibold text-primary-700"
                        >
                          {service.displayName}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
              <div>
                <Heading className="mb-4 mt-10" level={5}>
                  Subscribe to our newsletter
                </Heading>
                <Typography
                  size="large"
                  variant="lightDark"
                  className="font-medium"
                >
                  We’ll keep you in the loop on our best blog.
                </Typography>
                <NewsletterForm inputWidth="100%" />
              </div>
              <div className="my-6 flex space-x-6">
                {footer.achievements &&
                  footer.achievements.length > 0 &&
                  footer.achievements.map((achievement) => (
                    <img
                      key={achievement.id}
                      src={getImage(achievement.image.data?.attributes.url)}
                      alt={achievement.image.data.attributes.alternativeText}
                    />
                  ))}
              </div>

              <div>
                <Heading level={5} className="mb-6">
                  Follow Us
                </Heading>

                <div className="mb-10 flex space-x-5">
                  {footer.socialIcons &&
                    footer.socialIcons.length > 0 &&
                    footer.socialIcons.map((icon) => (
                      <Link key={icon.id} href={icon.href}>
                        <img
                          src={getImage(icon.icon.data?.attributes.url)}
                          alt={icon.icon.data.attributes.alternativeText}
                        />
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="mt-5 flex flex-col gap-6">
          <a href="#">
            <Typography
              className="font-medium"
              size="small"
              variant="mediumDark"
            >
              Terms of Use & Privacy Policy
            </Typography>
          </a>
          <Typography className="font-medium" size="small" variant="mediumDark">
            © {new Date().getFullYear()} Dhrubok Infotech Services Limited
          </Typography>
        </div>
      </Container>
    </section>
  );
};
