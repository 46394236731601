import { BASE_URL } from '@/constants/variables';

const getImage = (
  url: string | undefined,
  provider: 'local' | 's3' = 's3'
): string => {
  if (!url) return '';
  if (provider === 'local') {
    return BASE_URL.replace('/api/', '') + url;
  }

  if (provider === 's3') return url;
  return '';
};

export default getImage;
