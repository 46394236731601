/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokai as style } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

export const HTMLParser = ({ children }: { children: string }) => {
  const [isClient, setIsClient] = useState<boolean>(false);

  useEffect(() => {
    setIsClient(true);

    return () => {
      setIsClient(false);
    };
  }, []);

  const parsedHTML = ReactHtmlParser(children, {
    transform: (node: any) => {
      if (node.type === 'tag' && node.name === 'ul') {
        node.attribs = {
          ...node.attribs,
          class: 'list-disc list-inside px-[5%]',
        };
      }

      if (node.type === 'tag' && node.name === 'ol') {
        node.attribs = {
          ...node.attribs,
          class: 'list-decimal list-inside px-[5%]',
        };
      }

      if (node.type === 'tag' && node.name === 'a') {
        node.attribs = {
          ...node.attribs,
          class: 'text-secondary-1000',
        };
      }

      if (node.type === 'tag' && node.name === 'blockquote') {
        node.attribs = {
          ...node.attribs,
          class: 'font-semibold',
        };
      }

      if (node.type === 'tag' && node.name === 'h1') {
        node.attribs = {
          ...node.attribs,
          class: 'sm:text-6xl-72lh text-3xl font-semibold text-primary-900',
        };
      }

      if (node.type === 'tag' && node.name === 'h2') {
        node.attribs = {
          ...node.attribs,
          class:
            'sm:text-5xl text-xl font-semibold !leading-loose text-primary-900',
        };
      }

      if (node.type === 'tag' && node.name === 'h3') {
        node.attribs = {
          ...node.attribs,
          class: 'sm:text-3xl-lh text-xl font-semibold text-primary-900',
        };
      }

      if (node.type === 'tag' && node.name === 'h4') {
        node.attribs = {
          ...node.attribs,
          class: 'sm:text-2xl text-lg font-semibold text-primary-900',
        };
      }

      if (node.type === 'tag' && node.name === 'h5') {
        node.attribs = {
          ...node.attribs,
          class: 'text-xl-lh font-semibold text-primary-900',
        };
      }

      if (node.type === 'tag' && node.name === 'h6') {
        node.attribs = {
          ...node.attribs,
          class: 'text-lg font-semibold text-primary-900',
        };
      }

      if (node.type === 'tag' && node.name === 'code') {
        const language = node.attribs.class?.replace('language-', '') ?? '';
        const value = node.children[0].data;

        return (
          <div className="max-w-xs md:max-w-full">
            <SyntaxHighlighter
              language={language}
              style={style}
              wrapLines
              wrapLongLines
            >
              {value}
            </SyntaxHighlighter>
          </div>
        );
      }

      return undefined;
    },
  });

  return <React.Fragment>{isClient && parsedHTML}</React.Fragment>;
};
