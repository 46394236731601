// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ReCaptcha as GoogleReCaptcha,
  ReCaptchaProvider as GoogleReCaptchaProvider,
} from 'next-recaptcha-v3';
import { useCallback, useEffect, useState } from 'react';

import { RECAPTCHA_SITE_KEY } from '@/constants/variables';
import ApiFetch from '@/utils/ApiFetch';

import type { CheckTokenResponse, ReCaptchaProps } from './types';

export const ReCaptcha = ({ setFieldValue }: ReCaptchaProps) => {
  const [clientSide, setClientSide] = useState<boolean>(false);

  useEffect(() => {
    setClientSide(true);
  }, []);

  const verifyRecaptcha = useCallback(async (token: string) => {
    const response: Promise<CheckTokenResponse> = await ApiFetch({
      url: 'check-token',
      method: 'POST',
      header: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });

    const responseData = await response;

    if (responseData.success && responseData.score > 0.5) {
      setFieldValue('recaptchaValidation', true);
    } else {
      setFieldValue('recaptchaValidation', false);
    }
  }, []);

  return clientSide ? (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <GoogleReCaptcha onValidate={verifyRecaptcha} action="form_submission" />
    </GoogleReCaptchaProvider>
  ) : (
    <></>
  );
};
