import type { NextRouter } from 'next/router';
import React from 'react';

import { REDIRECT_TO_BLOG } from '@/constants/variables';
import type { BlogData } from '@/types/blogResponse';
import { ConvertStringToDateTime } from '@/utils/DateFormat';
import getImage from '@/utils/getImage';

import { Heading } from '../Heading';
import { ScreenResizer } from '../ScreenResizer';
import { Tag } from '../Tag';
import { Typography } from '../Typography';

export const BlogCard = ({
  data,
  router,
}: {
  data: BlogData;
  router: NextRouter;
}) => {
  return (
    <div className="flex w-full flex-col">
      <img
        src={getImage(data.attributes.featured_image?.data?.attributes.url)}
        alt={data.attributes.featured_image_alt ?? 'featured'}
        className="aspect-[77/48] w-full cursor-pointer rounded-t-lg object-cover object-center"
        onClick={() => router.push(REDIRECT_TO_BLOG(data.attributes.url))}
      />

      <div className={`my-4 flex flex-wrap space-x-2 sm:mt-8`}>
        {data.attributes.categories &&
          data.attributes.categories.data.length > 0 &&
          data.attributes.categories?.data.map((category, idx) => (
            <Tag
              key={category.id}
              index={idx}
              label={category.attributes.name ?? ''}
            />
          ))}
      </div>

      <div
        onClick={() => router.push(REDIRECT_TO_BLOG(data.attributes.url))}
        className="cursor-pointer"
      >
        <Heading level={4} weight="semibold">
          {data.attributes.title}
        </Heading>
      </div>

      <div className="flex items-center gap-3 text-sm font-semibold text-primary-800 sm:mt-3">
        <Typography size="small" variant="noStyle">
          {ConvertStringToDateTime(data.attributes.publishDate, 'only-date')}
        </Typography>
        <div>
          <ul className="list-inside list-disc">
            <li>{data.attributes.reading_time} mins read</li>
          </ul>
        </div>
      </div>
      <ScreenResizer displayOnly="desktop">
        <Typography className="mt-3" variant="lightDark" size="large">
          {data.attributes.description}
        </Typography>
      </ScreenResizer>
      <ScreenResizer displayOnly="mobile">
        <Typography className="font-medium" variant="lightDark">
          {data.attributes.description}
        </Typography>
      </ScreenResizer>
      {data.attributes.author && (
        <div className="mt-6 flex h-full items-end sm:mt-8">
          <div className=" flex items-center space-x-4 ">
            <div className="h-10 w-10 rounded-full">
              <img
                src={getImage(
                  data.attributes.author?.data?.attributes.profilePhoto?.data
                    ?.attributes.url
                )}
                alt={data.attributes.author?.data.attributes.displayName}
                className="rounded-full"
              />
            </div>

            <div className="flex flex-col">
              <Heading level={0} className="text-base">
                {data.attributes.author?.data.attributes.displayName}
              </Heading>
              <Typography size="small" variant="dark" className="font-normal">
                {data.attributes.author?.data.attributes.designation}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
