import type { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';

import { Alert } from '.';
import type { AlertProps } from './types';

export const useAlert = ({
  show,
  text,
  variant,
  setAlert,
}: AlertProps & { setAlert: Dispatch<SetStateAction<AlertProps>> }) => {
  const handleResetState = useCallback(() => {
    setAlert({
      text: '',
      show: false,
      variant: 'error',
    });
  }, []);

  const RenderAlert = () => {
    if (show)
      return (
        <Alert
          show={show}
          text={text}
          variant={variant}
          onCloseFn={handleResetState}
        />
      );
    return null;
  };

  return { RenderAlert };
};
