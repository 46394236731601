import React from 'react';
import Slider from 'react-slick';

import { Heading } from '../Heading';
import { ScreenResizer } from '../ScreenResizer';
import { Typography } from '../Typography';
import type { ArrowButtonProps, SlickSliderProps } from './types';

export const SlickSlider = ({ data, withContents }: SlickSliderProps) => {
  const slider = React.useRef(null);

  const ArrowButtonPrevious = ({
    imgSrc,
    imgAlt,
    onClick,
  }: ArrowButtonProps) => {
    return (
      <button
        onClick={onClick}
        className="absolute top-[35%] w-[31.2px] translate-y-[-35%]"
      >
        <img
          src={imgSrc}
          alt={imgAlt}
          // style={{
          //   width: "50px",
          //   height: "50px"
          // }}
        />
      </button>
    );
  };

  const ArrowButtonNext = ({ imgSrc, imgAlt, onClick }: ArrowButtonProps) => {
    return (
      <button
        onClick={onClick}
        className="absolute right-[-2px] top-[35%] w-[31.2px] translate-y-[-35%]"
      >
        <img
          src={imgSrc}
          alt={imgAlt}
          // style={{
          //   width: "50px",
          //   height: "50px"
          // }}
        />
      </button>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowButtonPrevious imgSrc={'/images/prevArrow.svg'} />,
    nextArrow: <ArrowButtonNext imgSrc={'/images/nextArrow.svg'} />,
  };

  return (
    <div>
      <Slider ref={slider} {...settings}>
        {data.map((item) =>
          withContents ? (
            <div key={item.id}>
              <div className="px-8 text-center sm:px-[90px]">
                <div className="mx-auto w-[200px] space-y-6">
                  <img src={item.img} alt={item.company} />
                </div>

                <Heading
                  level={4}
                  weight="semibold"
                  className="pb-4 pt-2 sm:pb-[29px] sm:pt-6"
                >
                  {item?.text}
                </Heading>

                <div className="mx-auto h-[80px] w-[80px]">
                  <img src={item.userImg} alt={item.company} />
                </div>
                <ScreenResizer displayOnly="desktop">
                  <Typography
                    size="xl"
                    className="pt-6 font-semibold"
                    variant="mediumDark"
                  >
                    {item?.authorityUser}
                  </Typography>
                  <Typography
                    size="xl"
                    className="pb-[29px] pt-3 font-medium"
                    variant="mediumDark"
                  >
                    {item?.designation}
                  </Typography>
                </ScreenResizer>
                <ScreenResizer displayOnly="mobile">
                  <Typography
                    size="large"
                    className="pt-4 font-semibold"
                    variant="mediumDark"
                  >
                    {item?.authorityUser}
                  </Typography>
                  <Typography
                    size="large"
                    className="pb-[29px] pt-3 font-medium"
                    variant="mediumDark"
                  >
                    {item?.designation}
                  </Typography>
                </ScreenResizer>
              </div>
            </div>
          ) : (
            <div key={item.id}>
              <img src={item.img} alt={'fdsfd'} />
            </div>
          )
        )}
      </Slider>
    </div>
  );
};
