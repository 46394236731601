import clsx from 'clsx';
import React, { useState } from 'react';
import ReactHTMLParser from 'react-html-parser';

import type { StartUpType } from '@/types/startup';

import { classes } from './styles';

export const Tabs = ({ startup }: { startup: StartUpType }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleSelectTab = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  return (
    <div>
      <div className="flex justify-between">
        {startup.attributes.tabs.map((tab, index) => (
          <div
            key={index}
            onClick={() => handleSelectTab(index)}
            className={clsx(
              classes.headTab.default,
              activeTab === index && classes.headTab.active,
              activeTab !== index && classes.headTab.deactive
            )}
          >
            {tab.heading}
          </div>
        ))}
      </div>
      <div className={classes.contentContainer}>
        {ReactHTMLParser(startup.attributes.tabs[activeTab]?.content ?? '')}
      </div>
    </div>
  );
};
