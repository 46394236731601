import clsx from 'clsx';
import { Formik } from 'formik';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Yup from 'yup';

// eslint-disable-next-line import/no-cycle
import {
  Button,
  FileUpload,
  Input,
  MailIcon,
  PhoneIcon,
  ReCaptcha,
  Textarea,
} from '@/components';
import {
  EXCEL_FILE_TYPES,
  PDF_FILE_TYPE,
  WORD_FILE_TYPES,
} from '@/constants/fileTypes';
import { REQUIRED_FIELD_MESSAGE } from '@/constants/variables';

import type { FormProps, InitialValues } from './types';

const initialValues: InitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  message: '',
  recaptchaValidation: false,
  files: null,
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  lastName: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  email: Yup.string().email().required(REQUIRED_FIELD_MESSAGE),
  phone: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  message: Yup.string().min(5).max(500).required(REQUIRED_FIELD_MESSAGE),
  recaptchaValidation: Yup.boolean(),
  files: Yup.mixed().nullable(),
});

export const ClientForm = ({ onSubmit, variant = 'default' }: FormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <Input
              name="firstName"
              label="First Name"
              placeholder="First Name"
              onChange={handleChange}
              value={values.firstName}
              transparent
              isDirty={touched.firstName}
              validationError={
                touched.firstName && errors.firstName
                  ? errors.firstName
                  : undefined
              }
            />
            <Input
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              onChange={handleChange}
              transparent
              value={values.lastName}
              isDirty={touched.lastName}
              validationError={
                touched.lastName && errors.lastName
                  ? errors.lastName
                  : undefined
              }
            />
            <div className={clsx(variant === 'default' && 'sm:col-span-2')}>
              <Input
                name="email"
                label="Email"
                placeholder="xyz@gmail.com"
                onChange={handleChange}
                transparent
                value={values.email}
                isDirty={touched.email}
                validationError={
                  touched.email && errors.email ? errors.email : undefined
                }
                startIcon={<MailIcon className="text-primary-500" />}
              />
            </div>
            <div className={clsx(variant === 'default' && 'sm:col-span-2')}>
              <Input
                name="phone"
                label="Phone number"
                placeholder="Phone number"
                onChange={handleChange}
                transparent
                value={values.phone}
                isDirty={touched.phone}
                validationError={
                  touched.phone && errors.phone ? errors.phone : undefined
                }
                startIcon={<PhoneIcon className="w-2 text-primary-500" />}
              />
            </div>
            <div className="sm:col-span-2">
              <Textarea
                name="message"
                label="Message"
                placeholder="Enter your message..."
                transparent
                onChange={handleChange}
                rows={4}
                value={values.message}
                validationError={
                  touched.message && errors.message ? errors.message : undefined
                }
              />
            </div>

            <div className="sm:col-span-2">
              <FileUpload
                name="files"
                supportedFormats={[
                  'image/jpeg',
                  'image/png',
                  ...EXCEL_FILE_TYPES,
                  ...WORD_FILE_TYPES,
                  PDF_FILE_TYPE,
                ]}
                accept={'*'}
                files={values.files ?? null}
                setFieldValue={setFieldValue}
                multiple
              />
            </div>
            <div className="sm:col-span-2">
              <ReCaptcha setFieldValue={setFieldValue} />
            </div>
          </div>
          <Button
            loading={isSubmitting}
            className="mt-5"
            label="Submit"
            variant="black"
            type="submit"
            disabled={isSubmitting}
          />
        </form>
      )}
    </Formik>
  );
};
