import React from 'react';

import type { FooterType } from '@/types/footer';
import type { MenuElementType } from '@/types/menu';

import Footer from './Footer';
import Navbar from './Navbar';

type MainLayoutProps = {
  children: React.ReactNode;
  menus: MenuElementType[];
  footer: FooterType;
};

const MainLayout = ({ children, menus, footer }: MainLayoutProps) => {
  return (
    <main className="relative bg-siteBg font-bodyFont">
      <Navbar menus={menus} />
      {children}
      <Footer footer={footer.attributes} />
    </main>
  );
};

export default MainLayout;
