import clsx from 'clsx';

import { classes } from './styles';
import type { IconProps } from './types';

export const RightArrowIcon = ({ className = '' }: IconProps) => (
  <svg
    width="100%"
    height="100%"
    className={clsx(classes.outline, className)}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.16669 7.00033H12.8334M12.8334 7.00033L7.00002 1.16699M12.8334 7.00033L7.00002 12.8337"
      // stroke="#5B5B5B"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
