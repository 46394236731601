import { ScreenResizer } from '@/components';
import type { MenuElementType } from '@/types/menu';

import { NavbarMobile } from './mobile';
import { NavbarWeb } from './web';

const Navbar = ({ menus }: { menus: MenuElementType[] }) => {
  return (
    <>
      <ScreenResizer displayOnly="desktop">
        <NavbarWeb items={menus} />
      </ScreenResizer>
      <ScreenResizer displayOnly="mobile">
        <NavbarMobile items={menus} />
      </ScreenResizer>
    </>
  );
};

export default Navbar;
