import clsx from 'clsx';

import { classes } from './styles';
import type { IconProps } from './types';

export const AnalysisIcon = ({ className = '' }: IconProps) => (
  <svg
    width="100%"
    height="100%"
    className={clsx(classes.outline, className)}
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.4706 35.5586C56.2647 28.8527 56.2647 17.8233 49.4706 11.0292C42.6765 4.23506 31.8235 4.23506 25.0294 11.0292C18.2353 17.8233 18.2353 28.8527 25.0294 35.5586C31.7353 42.2645 42.6765 42.2645 49.4706 35.5586ZM48.853 33.6174L38.5294 23.2939L48.9412 12.8821C54.2353 18.7939 54.0588 27.7939 48.853 33.6174ZM47.6177 11.6468L37.2059 21.9704L26.8824 11.6468C32.7941 6.35271 41.7059 6.35271 47.6177 11.6468ZM25.6471 12.8821C33.2353 20.4704 39.853 27.088 47.6177 34.8527C41.6177 40.2351 32.1765 40.2351 26.2647 34.2351C20.2647 28.4115 20.1765 18.9704 25.6471 12.8821Z"
      fill="black"
    />
    <path
      d="M5.79409 59.1177C11 59.1177 18.147 48.7059 20.3529 45.2648L20.7059 45.6177C21.4117 46.3236 22.647 46.3236 23.4411 45.6177L25.647 43.4118C25.9117 43.1471 26 42.8824 26.0882 42.6177C34.4706 47.4706 45.6764 46.5001 53.0882 39.0883C61.8235 30.353 61.8235 16.2354 53.0882 7.41182C44.3529 -1.32347 30.2353 -1.32347 21.4117 7.41182C14.0882 14.7354 12.9411 25.853 17.8823 34.4118C17.6176 34.5001 17.2647 34.6765 17.0882 34.853L14.8823 37.0589C14.1764 37.7648 14.1764 39.0001 14.8823 39.7942L15.2353 40.1471C11.7941 42.353 1.38232 49.4118 1.38232 54.7059C1.38232 56.4706 4.02938 59.1177 5.79409 59.1177ZM22.647 8.64712C30.6764 0.617705 43.7353 0.617705 51.7647 8.64712C59.7941 16.6765 59.7941 29.7354 51.7647 37.7648C43.7353 45.7942 30.6764 45.7942 22.647 37.7648C14.6176 29.8236 14.6176 16.6765 22.647 8.64712ZM16.1176 38.3824L18.3235 36.1765H18.4117H18.5L21.4117 39.0883L24.3235 42.0001C24.4117 42.0883 24.4117 42.1765 24.3235 42.1765L22.1176 44.3824C22.0294 44.4706 21.9411 44.4706 21.9411 44.3824C19.9117 42.353 17.2647 39.6177 16.1176 38.5589C16.0294 38.4706 16.0294 38.3824 16.1176 38.3824ZM16.4706 41.4706L19.0294 44.0295C16.3823 48.1765 9.76468 57.353 5.79409 57.353C4.99997 57.353 3.14703 55.5001 3.14703 54.7059C3.14703 50.7354 12.3235 44.1177 16.4706 41.4706Z"
      fill="black"
    />
  </svg>
);
