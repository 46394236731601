import clsx from 'clsx';
import Link from 'next/link';
import type { NextRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import ReactHTMLParser from 'react-html-parser';

import type { MenuElementType } from '@/types/menu';
import { IsActiveRoute } from '@/utils/IsActiveRoute';

import { Heading } from '../Heading';
import { Typography } from '../Typography';

export const NavDropdown = ({
  menu,
  routeObject,
}: {
  menu: MenuElementType;
  routeObject: NextRouter;
}) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const handleRouteChange = () => {
      setOpen(false);
    };

    routeObject.events.on('routeChangeStart', handleRouteChange);

    return () => {
      routeObject.events.off('routeChangeStart', handleRouteChange);
      setOpen(true);
    };
  }, [routeObject]);

  return (
    <div
      className={clsx(
        'invisible absolute right-[50%] top-[80px] z-[99] grid w-full origin-top-left translate-x-[50%] scale-y-0 grid-cols-2 gap-1 bg-footer opacity-0 shadow-md transition-all duration-500 ease-in-out group-hover:visible',
        'group-hover:scale-y-100 group-hover:opacity-100',
        !open && '!invisible !scale-y-0 !opacity-0'
      )}
    >
      {(menu.attributes.expandedTitle ||
        menu.attributes.expandedDescription) && (
        <div className="p-default">
          <Heading level={3} color="dark" className="pb-6">
            {menu.attributes.expandedTitle}
          </Heading>

          <Heading
            level={6}
            color="mediumDark"
            className="font-medium"
            weight="normal"
          >
            {ReactHTMLParser(menu.attributes.expandedDescription ?? '')}
          </Heading>
        </div>
      )}

      <div className="absolute right-[50%] top-[40%] translate-x-[-50%] translate-y-[-40%]">
        <img src="/images/line.png" alt="line" />
      </div>

      <div className="grid grid-cols-2 grid-rows-[max-content_1fr] gap-6 p-default">
        {menu.attributes.menuitems.map((subMenu, i) => (
          <div
            className={`pb-6 ${
              menu.attributes.menuitems.length < 4 ? 'col-span-2' : ''
            }`}
            key={i}
          >
            <Link href={`${subMenu.url}`}>
              <Typography
                className={`${
                  IsActiveRoute({
                    routeObject,
                    activeUrl: subMenu.url,
                  })
                    ? 'font-bold text-primary-900'
                    : 'font-medium'
                } transition-all hover:text-primary-900`}
                size="large"
              >
                {subMenu.text}
              </Typography>
              {subMenu.description && (
                <Typography className="font-medium text-slate-600" size="small">
                  {subMenu.description}
                </Typography>
              )}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
