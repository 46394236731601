import type { MenuGetResponse } from '@/types/menu';
import ApiFetch from '@/utils/ApiFetch';
import type { FormatFilterProps } from '@/utils/FormatFilter';

export async function getMenus({
  params,
  filters,
}: {
  params?: any;
  filters?: FormatFilterProps;
}): Promise<MenuGetResponse> {
  const res = await ApiFetch({
    url: 'mega-menu',
    params,
    filterParams: filters,
    method: 'GET',
  });

  return res;
}
