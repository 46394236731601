export const classes = {
  default: 'w-full rounded-lg bg-green-200 py-3 mb-2',
  background: {
    success: 'bg-green-200',
    error: 'bg-red-200',
  },
  color: {
    success: 'text-green-800',
    error: 'text-red-800',
  },
};
