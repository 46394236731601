import React from 'react';

import { ScreenResizer } from '@/components';
import type { FooterType } from '@/types/footer';

import { FooterMobile } from './mobile';
import { FooterWeb } from './web';

const Footer = ({ footer }: { footer: FooterType['attributes'] }) => {
  return (
    <>
      <ScreenResizer displayOnly="desktop">
        <FooterWeb footer={footer} />
      </ScreenResizer>
      <ScreenResizer displayOnly="mobile">
        <FooterMobile footer={footer} />
      </ScreenResizer>
    </>
  );
};

export default Footer;
