export const classes = {
  default:
    'py-3 px-4 w-full text-primary-500 border-px border-solid ring-1 ring-inset ring-primary-300 hover:ring-2 focus:border-primary-300 focus:ring-2',
  label: 'text-sm font-semibold text-primary-700',
  background: {
    transparent: 'bg-transparent placeholder:text-primary-500',
    white: 'bg-white',
  },
  errorBorder: 'border-red-700 border-2',
};
