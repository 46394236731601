import clsx from 'clsx';
import React from 'react';

import { Container } from '@/layouts/Container';

import { Heading } from '../Heading';
import { Typography } from '../Typography';
import type { HeaderSectionProps } from './types';

export const HeaderSection = ({
  title,
  description = '',
  desClassName = '',
  align = 'center',
  variant = 'dark',
  headingLevel = 1,
  renderH1 = true,
}: HeaderSectionProps) => {
  return (
    <Container>
      <Heading
        level={headingLevel}
        color={variant}
        className={clsx(
          align === 'center' && 'text-center',
          align === 'left' && 'text-left',
          align === 'right' && 'text-center'
        )}
        weight="semibold"
        renderH1={renderH1}
      >
        {title}
      </Heading>
      <div className="flex w-full justify-center">
        <Typography
          size="xl"
          variant={variant === 'white' ? 'noStyle' : 'lightDark'}
          className={clsx(
            'mt-6',
            desClassName,
            align === 'center' && 'text-center',
            align === 'left' && 'text-left',
            align === 'right' && 'text-center',
            variant === 'white' && 'text-textColorLight'
          )}
        >
          {description}
        </Typography>
      </div>
    </Container>
  );
};
