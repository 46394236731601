import React from 'react';

import { ScreenResizer } from '../ScreenResizer';
import { StoryCardMobile } from './mobile';
import type { StoryCardProps } from './types';
import { StoryCardWeb } from './web';

export const StoryCard = ({
  story,
  idx,
  variant = 'black',
  forHome,
}: StoryCardProps) => (
  <>
    <ScreenResizer displayOnly="desktop">
      <StoryCardWeb {...{ story, idx, variant, forHome }} />
    </ScreenResizer>
    <ScreenResizer displayOnly="mobile">
      <StoryCardMobile {...{ story, idx, variant, forHome }} />
    </ScreenResizer>
  </>
);
