export type FormatFilterProps = {
  param: string;
  condition: '$eq' | '$eqi' | '$ne' | '$null';
  value: string | boolean;
  withBrackets?: boolean | null;
}[];

const FormatFilter = (filters: FormatFilterProps): string => {
  const filterBrackets = (
    param: string,
    withBrackets: boolean | null = true
  ) => {
    if (withBrackets) {
      return `[${param}]`;
    }
    return param;
  };

  return filters
    .map(
      (filter) =>
        `filters${filterBrackets(
          filter.param,
          filter.withBrackets
        )}[${filter.condition.toString()}]=${encodeURIComponent(
          filter.value ? filter.value : ''
        )}`
    )
    .join('&');
};

export default FormatFilter;
