import { Formik } from 'formik';
import * as Yup from 'yup';

import { REQUIRED_FIELD_MESSAGE } from '@/constants/variables';
import { useNewsletter } from '@/hooks/useNewsletter';

import { Button } from '../Button';
import { MailIcon } from '../Icons';
import { Input } from '../Input';
import type { FormProps, NewsletterInitialValues } from './types';

const initialValues: NewsletterInitialValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(REQUIRED_FIELD_MESSAGE),
});

export const NewsletterForm = ({ inputWidth }: FormProps) => {
  const { RenderAlert, handleNewsletterSubmit } = useNewsletter();

  return (
    <div className="mt-5">
      {RenderAlert()}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleNewsletterSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mt-5 flex items-start gap-6">
              <div style={{ width: inputWidth }}>
                <Input
                  name="email"
                  transparent
                  placeholder="Your email address.."
                  value={values.email}
                  startIcon={<MailIcon className="text-primary-500" />}
                  onChange={handleChange}
                  isDirty={touched.email}
                  validationError={
                    touched.email && errors.email ? errors.email : undefined
                  }
                />
              </div>

              <Button
                loading={isSubmitting}
                type="submit"
                variant="black"
                label="Subscribe"
                size="small"
                disabled={isSubmitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
