import clsx from 'clsx';
import React from 'react';

import { classes } from './styles';
import type { HeadingProps } from './type';

export const Heading = ({
  children,
  level = 1,
  weight = 'bold',
  className = '',
  color = 'dark',
  renderH1 = false,
  style,
}: HeadingProps) => {
  return (
    <React.Fragment>
      {renderH1 ? (
        <h1
          className={clsx(
            '',
            classes.color[color],
            classes.level[level],
            classes.fontWeight[weight],
            className
          )}
          style={{ ...style }}
        >
          {children}
        </h1>
      ) : (
        <h2
          className={clsx(
            '',
            classes.color[color],
            classes.level[level],
            classes.fontWeight[weight],
            className
          )}
          style={{ ...style }}
        >
          {children}
        </h2>
      )}
    </React.Fragment>
  );
};
