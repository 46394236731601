import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { classes } from './styles';
import type { TypographyProps } from './types';

export const Typography = ({
  children,
  size = 'medium',
  variant = 'mediumDark',
  className = '',
  style,
}: TypographyProps) => {
  return (
    <p
      className={twMerge(
        clsx(classes.size[size], classes.variant[variant], className)
      )}
      style={{ ...style }}
    >
      {children}
    </p>
  );
};
