export const classes = {
  headTab: {
    default:
      'flex flex-col justify-center py-2 sm:py-7 text-center text-lg sm:text-3xl font-bold w-full cursor-pointer font-titleFont',
    deactive: 'text-primary-800',
    active: 'bg-footer border-t-4 border-solid border-primary-900',
  },
  contentContainer:
    'text-xl text-primary-700 w-full py-6 sm:py-default px-1 sm:px-10 bg-footer',
};
