import { useEffect } from 'react';

export const ClutchWidget = ({ variant }: { variant: 'about' | 'home' }) => {
  useEffect(() => {
    // Load the Clutch widget script dynamically
    const script = document.createElement('script');
    script.src = 'https://widget.clutch.co/static/js/widget.js';
    script.async = false;
    document.head.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      {variant === 'home' && (
        <div
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="clutch-widget sm:mt-4"
          data-url="https://widget.clutch.co/"
          data-widget-type="2"
          data-height="45"
          data-nofollow="true"
          data-expandifr="true"
          data-clutchcompany-id="223544"
        ></div>
      )}
      {variant === 'about' && (
        <div
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="clutch-widget"
          data-url="https://widget.clutch.co/"
          data-widget-type="11"
          data-height="360"
          data-nofollow="true"
          data-expandifr="true"
          data-scale="100"
          data-clutchcompany-id="223544"
        />
      )}
    </>
  );
};
