import { token } from '@/constants/token';
import { BASE_URL } from '@/constants/variables';

import type { FormatFilterProps } from './FormatFilter';
import FormatFilter from './FormatFilter';

type ParamsTypes = {
  [key: string]: string | number;
};

interface ApiFetchProps {
  url: string;
  params?: ParamsTypes | '';
  filterParams?: FormatFilterProps;
  body?: any;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  header?: object;
}

export default async function ApiFetch({
  url,
  params = '',
  filterParams,
  body = null,
  method,
  header,
}: ApiFetchProps) {
  // @ts-ignore
  const urlParams = new URLSearchParams(params).toString();
  const filters = filterParams ? `&${FormatFilter(filterParams)}` : '';

  const requestUrl = `${BASE_URL + url}?${urlParams}${filters}`;

  const options = {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      ...header,
    },
    body: body ?? null,
  };

  const res = await fetch(requestUrl, options);

  const resJSON = await res.json();

  // console.log('🚀 ~ file: ApiFetch.ts:34 ~ requestUrl:', requestUrl);
  // console.log('🚀 ~ file: ApiFetch.ts:43 ~ options:', options);
  // console.log('🚀 ~ file: ApiFetch.ts:49 ~ resJSON:', resJSON);

  if (!res.ok) {
    return {
      status: resJSON.error?.status || '',
      message: resJSON.error?.message || '',
    };
  }

  return resJSON;
}
