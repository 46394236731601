import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { IsActiveRoute } from '@/utils/IsActiveRoute';

import { NavDropdown } from '../NavDropdown';
import { Typography } from '../Typography';
import type { ListItemsProps } from './types';

export const ListItems = ({ items }: ListItemsProps) => {
  const router = useRouter();

  return (
    <ul className="flex gap-10">
      {items
        ?.sort((a, b) => a.attributes.order - b.attributes.order)
        .map((item, idx) => (
          <li
            key={idx}
            className={`group flex h-[84px] cursor-pointer flex-col justify-center py-5 font-medium text-primary-900`}
          >
            {item.attributes.url === '/about' ? (
              <a href={item?.attributes.url ?? '/'} className="hover:border-0">
                <div
                  className={`${
                    item.attributes.menuitems.length > 0
                      ? 'flex items-center'
                      : ''
                  } `}
                >
                  <Typography
                    size="medium"
                    variant="lightDark"
                    className={`${
                      IsActiveRoute({
                        routeObject: router,
                        activeUrl: item.attributes.url,
                      })
                        ? 'font-bold text-primary-900'
                        : 'font-medium text-customPrimary'
                    } transition-all hover:text-primary-900`}
                  >
                    {item.attributes.name}
                  </Typography>

                  {item.attributes.menuitems.length > 0 && (
                    <div className="ml-2">
                      <img
                        src="/images/down_arrow.svg"
                        alt="arrow"
                        className="h-[5px] w-[14px]"
                      />
                    </div>
                  )}
                </div>
              </a>
            ) : (
              <Link
                href={item?.attributes?.url ?? '#'}
                className="hover:border-0"
              >
                <div
                  className={`${
                    item.attributes.menuitems.length > 0
                      ? 'flex items-center'
                      : ''
                  } `}
                >
                  <Typography
                    size="medium"
                    variant="lightDark"
                    className={`${
                      IsActiveRoute({
                        routeObject: router,
                        activeUrl: item.attributes.url,
                      })
                        ? 'font-bold text-primary-900'
                        : 'font-medium text-customPrimary'
                    } transition-all hover:text-primary-900`}
                  >
                    {item.attributes.name}
                  </Typography>

                  {item.attributes.menuitems.length > 0 && (
                    <div className="ml-2">
                      <img
                        src="/images/down_arrow.svg"
                        alt="arrow"
                        className="h-[10px] w-[14px]"
                      />
                    </div>
                  )}
                </div>
              </Link>
            )}

            {item.attributes.menuitems.length > 0 && (
              <NavDropdown menu={item} routeObject={router} />
            )}
          </li>
        ))}
    </ul>
  );
};
