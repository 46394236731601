import ApiFetch from '@/utils/ApiFetch';

export async function postNewsletter({ body }: { body: FormData }) {
  const res = await ApiFetch({
    url: 'newsletters',
    body,
    method: 'POST',
  });

  return res;
}
