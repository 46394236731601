import { useState } from 'react';

import { postNewsletter } from '@/API/newsletterAPI';
import { useAlert } from '@/components';
import type { AlertProps } from '@/components/Alert/types';
import type { NewsletterInitialValues } from '@/components/NewsletterForm/types';

export const useNewsletter = () => {
  const [alert, setAlert] = useState<AlertProps>({
    show: false,
    variant: 'success',
    text: '',
  });

  const { RenderAlert } = useAlert({
    show: alert.show,
    variant: alert.variant,
    text: alert.text,
    setAlert,
  });

  const handleNewsletterSubmit = async (
    values: NewsletterInitialValues,
    {
      setSubmitting,
      resetForm,
    }: { setSubmitting: (status: boolean) => void; resetForm: () => void }
  ) => {
    setSubmitting(true);

    const formData = new FormData();
    formData.append('data', JSON.stringify(values));

    // submitting form
    try {
      const response = await postNewsletter({ body: formData });

      if (response.status === 400) {
        setAlert({
          show: true,
          text: 'Already subscribed',
          variant: 'error',
        });
      } else {
        setAlert({
          show: true,
          text: 'Successfully subscribed',
          variant: 'success',
        });
        resetForm();
      }
    } catch (error) {
      setAlert({
        show: true,
        text: 'Something went wrong',
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return { handleNewsletterSubmit, RenderAlert };
};
