import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { SpinnerIcon } from '../Icons';
import { classes } from './styles';
import type { ButtonProps } from './types';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      loading = false,
      size = 'medium',
      label,
      className = '',
      startIcon,
      endIcon,
      ...htmlButtonElementProps
    }: ButtonProps,
    ref
  ): JSX.Element => {
    return (
      <button
        ref={ref}
        className={twMerge(
          clsx(
            variant !== 'text' && classes.default,
            variant !== 'text' &&
              htmlButtonElementProps.disabled &&
              classes.variant.disabled,
            htmlButtonElementProps.disabled && classes.disabled,
            variant !== 'text' && classes.size[size],
            classes.variant[variant],
            className
          )
        )}
        {...htmlButtonElementProps}
      >
        <div className="flex items-center gap-2">
          {startIcon && <div>{startIcon}</div>}
          {loading && <SpinnerIcon />}
          {label && <span className="whitespace-nowrap">{label}</span>}
          {endIcon && <div>{endIcon}</div>}
        </div>
      </button>
    );
  }
);

Button.displayName = 'Button';
