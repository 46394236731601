import clsx from 'clsx';

import { classes } from './styles';
import type { IconProps } from './types';

export const FacebookIcon = ({ className = '' }: IconProps) => (
  <svg
    className={clsx(classes.outline, className)}
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_827_3271)">
      <path
        d="M20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 14.9912 3.65684 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3084 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.95 6.5625 11.5625 7.3334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3432 19.1283 20 14.9912 20 10Z"
        fill="#151515"
      />
    </g>
    <defs>
      <clipPath id="clip0_827_3271">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
