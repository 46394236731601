import ApiFetch from '@/utils/ApiFetch';

export async function postContact({ body }: { body: FormData }) {
  const res = await ApiFetch({
    url: 'contacts',
    body,
    method: 'POST',
  });

  return res;
}
