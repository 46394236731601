// import logo from 'images/logo.png'

import { ListItems } from '@/components';
import type { ListItemsProps } from '@/components/ListItems/types';

import { Container } from '../../Container';

export const NavbarWeb = ({ items }: ListItemsProps) => {
  return (
    <header
      className="fixed z-[1000] h-[80px] w-full bg-gradient-to-r from-siteBg/60  to-secondary-200/60 bg-cover bg-no-repeat shadow-sm backdrop-blur-lg"

      // style={{
      //   background: `url('/images/navbg.png')`,
      //   backgroundOrigin: 'initial',
      //   backgroundPosition: 'right',
      // }}
    >
      <div>
        <Container>
          <div className="flex items-center justify-between">
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a href="/" className="py-[10px] hover:border-none">
              <div className="flex h-default w-[200px] items-center justify-center">
                <img
                  src={'/images/logo.png'}
                  alt={'logo'}
                  className="mb-[10px]"
                />
              </div>
            </a>
            <div className="flex items-center justify-center">
              <ListItems items={items} />
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};
