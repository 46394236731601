export const classes = {
  default: 'font-bold text-lg w-fit transition-all rounded-lg',
  disabled: 'pointer-events-none opacity-80',
  size: {
    xs: 'py-1 px-4',
    small: 'py-2.5 px-[18px]',
    medium: 'py-2 px-6 sm:py-3.5 sm:px-8 ',
    large: 'py-3 px-7 sm:py-4 sm:px-10',
  },
  variant: {
    black: 'bg-primary-900 text-white hover:bg-white hover:text-primary-900',
    white: 'bg-white text-primary-900 hover:bg-primary-900 hover:text-white',
    outlined:
      'border border-primary-300 text-white hover:border-primary-700 hover:text-primary-700',
    text: '',
    disabled: 'bg-primary-200 text-primary-800',
  },
};
