type ContainerProps = {
  children: React.ReactNode;
  hiddenOverflow?: boolean;
  className?: string;
};

export const Container = ({
  children,
  hiddenOverflow = false,
  className = '',
}: ContainerProps) => {
  return (
    <div
      className={`mx-auto w-full max-w-[85rem] ${
        !hiddenOverflow ? 'overflow-hidden' : ''
      } px-5 sm:px-sidePadding ${className}`}
    >
      {children}
    </div>
  );
};
