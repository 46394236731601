import clsx from 'clsx';
import React, { useState } from 'react';

import { useContact } from '@/hooks/useContact';

// eslint-disable-next-line import/no-cycle
import { ClientForm } from '../../page-components/Home/ContactForm/ClientForm';
import { Heading } from '../Heading';
import { classes } from './styles';

export const GetQuote = () => {
  const [open, setOpen] = useState(false);

  const { handleSubmit, RenderAlert } = useContact();

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div onClick={handleToggle} className={classes.mainButton}>
        <Heading level={6} color="white" className="text-center">
          Get a free quote!
        </Heading>
      </div>
      {/* fixed top-[30%] right-20 w-[500px] animate-slideInRight */}
      <div
        className={clsx(
          classes.formContainer,
          open && classes.contentVisible,
          !open && classes.contentInvisible
        )}
      >
        <button
          className="absolute right-0 top-0 w-fit bg-primary-800 px-4 py-1.5 font-semibold text-white"
          onClick={handleToggle}
        >
          X
        </button>
        <Heading level={3} className="text-center">
          Get a free quote!
        </Heading>
        <div className="mt-10 max-h-[75vh] overflow-auto pb-10 transition-all">
          {RenderAlert()}
          {open && <ClientForm variant="quote" onSubmit={handleSubmit} />}
        </div>
      </div>
    </div>
  );
};
