import clsx from 'clsx';

import { classes } from './styles';
import type { IconProps } from './types';

export const PlanningIcon = ({ className = '' }: IconProps) => (
  <svg
    width="100%"
    height="100%"
    className={clsx(classes.outline, className)}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.7188 56.5312C41.25 58.0312 39.8438 59.0625 38.1563 59.0625H6.65625C4.59375 59.0625 2.90625 57.375 2.90625 55.3125V11.9062C2.90625 9.84375 4.59375 8.15625 6.65625 8.15625H10.3125M34.5 8.15625H38.1563C40.2188 8.15625 41.9063 9.84375 41.9063 11.9062V13.125M29.7188 10.875H15.0938C14.5313 10.875 14.0625 10.4062 14.0625 9.84375V6.46875C14.0625 5.90625 14.5313 5.4375 15.0938 5.4375H29.7188C30.2813 5.4375 30.75 5.90625 30.75 6.46875V9.84375C30.75 10.4062 30.2813 10.875 29.7188 10.875Z"
      stroke="#151515"
      strokeWidth="1.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4062 5.4375C23.6489 5.4375 24.6562 4.43014 24.6562 3.1875C24.6562 1.94486 23.6489 0.9375 22.4062 0.9375C21.1636 0.9375 20.1562 1.94486 20.1562 3.1875C20.1562 4.43014 21.1636 5.4375 22.4062 5.4375Z"
      stroke="#151515"
      strokeWidth="1.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42 52.875C50.3878 52.875 57.1875 46.0753 57.1875 37.6875C57.1875 29.2997 50.3878 22.5 42 22.5C33.6122 22.5 26.8125 29.2997 26.8125 37.6875C26.8125 46.0753 33.6122 52.875 42 52.875Z"
      stroke="#151515"
      strokeWidth="1.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42 39.375C42.932 39.375 43.6875 38.6195 43.6875 37.6875C43.6875 36.7555 42.932 36 42 36C41.068 36 40.3125 36.7555 40.3125 37.6875C40.3125 38.6195 41.068 39.375 42 39.375Z"
      stroke="#151515"
      strokeWidth="1.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.9998 36.0938V30.5625M52.031 26.3438L54.3748 23.9062M31.781 26.3438L29.3435 23.9062"
      stroke="#151515"
      strokeWidth="1.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.9998 22.5C43.5531 22.5 44.8123 21.2408 44.8123 19.6875C44.8123 18.1342 43.5531 16.875 41.9998 16.875C40.4465 16.875 39.1873 18.1342 39.1873 19.6875C39.1873 21.2408 40.4465 22.5 41.9998 22.5Z"
      stroke="#151515"
      strokeWidth="1.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42 25.875V26.3437M42 49.125V49.5938M53.8125 37.6875H53.3438M30.5625 37.6875H30.0937M50.3437 29.3437L50.0625 29.7187M33.9375 45.75L33.5625 46.125M50.3437 46.125L50.0625 45.75M33.9375 29.7187L33.5625 29.3437M37.4062 26.8125L37.5937 27.1875M46.3125 48.2812L46.5 48.6563M52.875 33.1875L52.5 33.375M31.4062 42.0937L31.0312 42.2812M46.5 26.8125L46.3125 27.1875M37.5937 48.2812L37.4062 48.6563M52.875 42.2812L52.5 42.0937M31.4062 33.375L31.0312 33.1875M9 18.75L10.4062 20.1562L14.5312 16.0312M18.6562 18.0937H30.5625M9 26.8125L10.4062 28.2187L14.5312 24.0937M18.6562 26.1562H22.9687M9 34.875L10.4062 36.2812L14.5312 32.1562M18.6562 34.2187H21.4687M9 42.9375L10.4062 44.3437L14.5312 40.2187M18.6562 42.2812H22.0312M9 51L10.4062 52.5L14.5312 48.2812M18.6562 50.3437H25.5"
      stroke="#151515"
      strokeWidth="1.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
