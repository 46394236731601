import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';

import {
  BurgerIcon,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Collapse,
  CrossIcon,
  Typography,
} from '@/components';
import type { ListItemsProps } from '@/components/ListItems/types';
import { TALK_TO_US_URL } from '@/constants/variables';

import { Container } from '../../Container';

export const NavbarMobile = ({ items }: ListItemsProps) => {
  const [open, setOpen] = useState(false);

  const router = useRouter();

  const handleToggle = useCallback(() => setOpen(!open), [open]);

  return (
    <div>
      <header className="fixed inset-x-0 -top-1 z-[900] w-full bg-siteBg bg-no-repeat shadow-md">
        <div>
          <Container>
            <div className="flex justify-between">
              {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
              <a href="/" className="hover: border-none py-5">
                <div className="flex  items-center justify-center ">
                  <img
                    src={'/images/logo.png'}
                    alt={'logo'}
                    className="w-[100px]"
                  />
                </div>
              </a>
              <Button
                variant="text"
                onClick={handleToggle}
                label={undefined}
                startIcon={<BurgerIcon />}
              />
            </div>
          </Container>
        </div>
      </header>
      {open && (
        <div
          className="fixed left-0 top-0 z-[1000] h-full w-full overflow-y-scroll bg-siteBg bg-cover bg-center bg-no-repeat px-5 pt-4"
          style={{ backgroundImage: `url('/assets/common/mobile-navbar.png')` }}
        >
          <div className="flex items-center justify-between">
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a href="/" className="hover: border-none py-5">
              <div className="flex  items-center justify-center ">
                <img
                  src={'/images/logo.png'}
                  alt={'logo'}
                  className="w-[100px]"
                />
              </div>
            </a>
            <div className="flex items-center gap-6">
              <Button
                variant="black"
                size="xs"
                label="Talk To Us"
                onClick={() => {
                  router.push(TALK_TO_US_URL);
                  handleToggle();
                }}
              />
              <Button
                variant="text"
                onClick={handleToggle}
                label={undefined}
                startIcon={<CrossIcon className="h-5 w-5" />}
              />
            </div>
          </div>

          <div className="mt-14 flex flex-col gap-6">
            {items &&
              items
                .sort((a, b) => a.attributes.order - b.attributes.order)
                .map((item, index) => (
                  <>
                    {item.attributes.menuitems &&
                    item.attributes.menuitems.length > 0 ? (
                      <Collapse
                        collapsible={
                          item?.attributes?.url ? (
                            <Link
                              href={item?.attributes?.url ?? '#'}
                              onClick={handleToggle}
                            >
                              <Typography
                                size="large"
                                variant="mediumDark"
                                className="font-semibold"
                              >
                                {item.attributes.name}
                              </Typography>
                            </Link>
                          ) : (
                            <Typography
                              size="large"
                              variant="mediumDark"
                              className="font-semibold"
                            >
                              {item.attributes.name}
                            </Typography>
                          )
                        }
                        iconBefore={<ChevronDownIcon />}
                        iconAfter={<ChevronUpIcon />}
                      >
                        <div className="mt-6 flex flex-col gap-6">
                          {item.attributes.menuitems.map((subMenu, i) => (
                            <Link
                              key={i}
                              className="hover: border-none"
                              href={subMenu.url}
                              onClick={handleToggle}
                            >
                              <Typography
                                size="large"
                                variant="mediumDark"
                                className="font-semibold"
                              >
                                {subMenu.text}
                              </Typography>
                            </Link>
                          ))}
                        </div>
                      </Collapse>
                    ) : (
                      <>
                        {/* to reload the page in order to clutch reload  */}
                        {item.attributes.url === '/about' ? (
                          <a
                            href={item.attributes.url ?? ''}
                            key={index}
                            className="hover: border-none"
                            onClick={handleToggle}
                          >
                            <Typography
                              size="large"
                              variant="mediumDark"
                              className="font-semibold"
                            >
                              {item.attributes.name}
                            </Typography>
                          </a>
                        ) : (
                          <Link
                            href={item.attributes.url ?? ''}
                            key={index}
                            className="hover: border-none"
                            onClick={handleToggle}
                          >
                            <Typography
                              size="large"
                              variant="mediumDark"
                              className="font-semibold"
                            >
                              {item.attributes.name}
                            </Typography>
                          </Link>
                        )}
                      </>
                    )}
                  </>
                ))}
          </div>
        </div>
      )}
    </div>
  );
};
