import { useState } from 'react';

import { postContact } from '@/API/contactAPI';
import type { AlertProps } from '@/components/Alert/types';
import { useAlert } from '@/components/Alert/useAlert';
import type { InitialValues } from '@/page-components/Home/ContactForm/types';

export const useContact = () => {
  const [alert, setAlert] = useState<AlertProps>({
    show: false,
    variant: 'success',
    text: '',
  });

  const { RenderAlert } = useAlert({
    show: alert.show,
    variant: alert.variant,
    text: alert.text,
    setAlert,
  });

  const handleSubmit = async (
    values: InitialValues,
    {
      setSubmitting,
      resetForm,
    }: { setSubmitting: (status: boolean) => void; resetForm: () => void }
  ) => {
    setSubmitting(true);

    const data = values;

    if (data.recaptchaValidation === false) {
      setAlert({
        show: true,
        text: 'Recaptcha validation failed',
        variant: 'error',
      });
    } else {
      const formData = new FormData();
      // files
      if (data.files && Object.values(data.files).length > 0) {
        Object.values(data.files).forEach((file) =>
          formData.append(`files.attachments`, file, file.name)
        );
      }

      // deleting file key & recaptcha
      delete data.recaptchaValidation;
      delete data.files;
      formData.append('data', JSON.stringify(data));

      // submitting form
      try {
        await postContact({ body: formData });
        setAlert({
          show: true,
          text: 'Your feedback received',
          variant: 'success',
        });
        resetForm();
      } catch (error) {
        setAlert({
          show: true,
          text: 'Something went wrong',
          variant: 'error',
        });
      }
    }

    setSubmitting(false);
  };

  return { handleSubmit, RenderAlert };
};
