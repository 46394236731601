const ConvertStringToDateTime = (
  timeString: string,
  type?: 'only-date' | 'only-time' | 'all'
) => {
  const formattedDate = new Date(timeString).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  const formattedTime = new Date(timeString).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  });

  if (type === 'only-date') {
    return formattedDate;
  }
  if (type === 'only-time') {
    return formattedTime;
  }
  return `${formattedDate} ${formattedTime}`;
};

export { ConvertStringToDateTime };
