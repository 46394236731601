import Link from 'next/link';

import { Heading, Typography } from '@/components';
import { NewsletterForm } from '@/components/NewsletterForm';
import type { FooterType } from '@/types/footer';
import getImage from '@/utils/getImage';

import { Container } from '../../Container';

export const FooterWeb = ({ footer }: { footer: FooterType['attributes'] }) => {
  return (
    <section className="bg-footer py-default">
      <Container>
        <div className="grid grid-cols-2 gap-default pb-default">
          <div>
            <div className="h-default w-[200px]">
              <img src={'/images/logo.png'} alt="logo" />
            </div>
            <Typography size="large" variant="lightDark" className="mb-10 mt-6">
              {footer.description}
            </Typography>
            <div className="mb-10 flex space-x-6">
              {footer.achievements &&
                footer.achievements.map((achievement) => (
                  <img
                    key={achievement.id}
                    src={getImage(achievement.image.data?.attributes.url)}
                    alt={achievement.image.data.attributes.alternativeText}
                    className="h-10 object-contain"
                  />
                ))}
            </div>

            <div>
              <Heading level={5} className="mb-6">
                Follow Us
              </Heading>

              <div className="mb-10 flex h-[34px] space-x-5">
                {footer.socialIcons &&
                  footer.socialIcons.map((item) => (
                    <Link key={item.id} href={item.href}>
                      <img
                        src={getImage(item.icon.data?.attributes.url)}
                        alt={item.icon.data.attributes.alternativeText}
                        className="h-8 object-contain"
                      />
                    </Link>
                  ))}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-default">
            <div>
              <Heading className="mb-6" level={5}>
                Navigations
              </Heading>
              <div>
                <ul className="flex flex-col space-y-4">
                  {footer.navigationPage &&
                    footer.navigationPage.map((page) => (
                      <li key={page.id}>
                        {page.href === '/' || page.href === '/about' ? (
                          <a
                            href={page.href}
                            className="text-lg font-semibold text-primary-700"
                          >
                            {page.displayName}
                          </a>
                        ) : (
                          <Link
                            href={page.href}
                            className="text-lg font-semibold text-primary-700"
                          >
                            {page.displayName}
                          </Link>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-span-2">
              <Heading className="mb-6" level={5}>
                Services
              </Heading>
              <div>
                <ul className="flex flex-col space-y-4">
                  {footer.navigationServices &&
                    footer.navigationServices.map((service) => (
                      <li key={service.id}>
                        <Link
                          href={service.href}
                          className="text-lg font-semibold text-primary-700"
                        >
                          {service.displayName}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
              <Heading className="mb-4 mt-10" level={5}>
                Subscribe to our newsletter
              </Heading>
              <Typography
                size="large"
                variant="lightDark"
                className="font-medium"
              >
                We’ll keep you in the loop on our best blog.
              </Typography>
              <NewsletterForm inputWidth="100%" />
            </div>
          </div>
        </div>

        <hr />

        <div className="mt-default flex justify-between">
          <Typography className="font-medium" size="large" variant="dark">
            © {new Date().getFullYear()} Dhrubok Infotech Services Ltd.
          </Typography>
          <a href="#">
            <Typography className="font-medium" size="large" variant="dark">
              Terms of Use & Privacy Policy
            </Typography>
          </a>
        </div>
      </Container>
    </section>
  );
};
