// google recaptcha
const RECAPTCHA_SITE_KEY = '6LdTBNYaAAAAAJ5rxSgcE4BJcmBboYUDT3NGqBiQ';

const TALK_TO_US_URL = '/contact';

const REQUIRED_FIELD_MESSAGE = 'This field is required';

const REDIRECT_TO_BLOG = (url: string) => `/blogs/${url}`;

const REDIRECT_TO_SUCCESS_STORIES = (url: string) => `/success-stories/${url}`;

const REDIRECT_TO_DEVELOPER_RESOURCES = (url: string) =>
  `/developer-resources/${url}`;

const REDIRECT_TO_NEWS = (url: string) => `/news-updates/${url}`;

const LOAD_ALL_POSTS = 'All blog posts';

const BASE_URL = process.env.API_URL ?? 'https://app.dhrubokinfotech.com/api/';

const STORIES_BG_COLORS = [
  // dBlue
  '#0C46A0',
  // DGreen
  '#289C1B',
  // DTeal
  '#6ABEB9',
  // DDeepBlue
  '#071B5D',
  // DLightBlue
  '#0084CA',
];

export {
  BASE_URL,
  LOAD_ALL_POSTS,
  RECAPTCHA_SITE_KEY,
  REDIRECT_TO_BLOG,
  REDIRECT_TO_DEVELOPER_RESOURCES,
  REDIRECT_TO_NEWS,
  REDIRECT_TO_SUCCESS_STORIES,
  REQUIRED_FIELD_MESSAGE,
  STORIES_BG_COLORS,
  TALK_TO_US_URL,
};
