export const classes = {
  size: {
    small: 'text-sm',
    medium: 'text-base',
    large: 'sm:text-lg text-sm',
    xl: 'sm:text-xl text-base',
  },
  variant: {
    dark: 'text-primary-900',
    mediumDark: 'text-primary-800',
    lightDark: 'text-primary-700',
    noStyle: '',
  },
};
