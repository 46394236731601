import type { NextRouter } from 'next/router';

type IsActiveRouteProps = {
  routeObject: NextRouter;
  activeUrl: string | null | undefined;
};

export const IsActiveRoute = ({
  routeObject,
  activeUrl,
}: IsActiveRouteProps): boolean => {
  if (!activeUrl) return false;
  if (routeObject.pathname.includes(activeUrl)) return true;

  return false;
};
