const EXCEL_FILE_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];

const WORD_FILE_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const PDF_FILE_TYPE = 'application/pdf';
const ZIP_FILE_TYPE = 'application/zip';

export { EXCEL_FILE_TYPES, PDF_FILE_TYPE, WORD_FILE_TYPES, ZIP_FILE_TYPE };
