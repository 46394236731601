import type { FooterGetResponse } from '@/types/footer';
import ApiFetch from '@/utils/ApiFetch';

export async function getFooter({
  params,
}: {
  params: any;
}): Promise<FooterGetResponse> {
  const res = await ApiFetch({
    url: 'footer',
    params,
    method: 'GET',
  });

  return res;
}
