import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

import { Heading } from '@/components/Heading';
import { REDIRECT_TO_SUCCESS_STORIES } from '@/constants/variables';
import getImage from '@/utils/getImage';

import { Button } from '../../Button';
import type { StoryCardProps } from '../types';

export const StoryCardWeb = ({
  story,
  idx,
  variant,
  forHome,
}: StoryCardProps) => {
  const router = useRouter();

  return (
    <>
      {Math.floor(idx) % 2 !== 0 && (
        <div className="flex items-center gap-[140px]">
          <div className="basis-1/2" data-aos="fade-right">
            <img
              src={getImage(story.attributes.story_image?.data?.attributes.url)}
              alt={
                story.attributes.story_image.data.attributes.alternativeText ??
                ''
              }
              className="aspect-[121/73] w-full rounded-lg object-cover object-center"
            />
          </div>
          <div className="basis-1/2" data-aos="fade-left">
            {/* <div className="w-[215px]">
              <img src={story.thumbImg} alt={'thumb'} />
            </div> */}
            <div className="mb-[20px] mt-[30px]">
              <Heading
                level={2}
                color={
                  story.attributes.textColor === 'white' ? 'white' : 'dark'
                }
                style={{
                  color:
                    !forHome && story.attributes.textColor
                      ? story.attributes.textColor
                      : undefined,
                }}
              >
                {story.attributes.title}
              </Heading>
            </div>
            <div className="mb-10">
              <p
                className={clsx(
                  'text-base sm:text-xl',
                  !story.attributes.textColor &&
                    variant === 'black' &&
                    'text-primary-700',
                  !story.attributes.textColor &&
                    variant === 'white' &&
                    'text-white'
                )}
                style={{
                  color:
                    !forHome && story.attributes.textColor
                      ? story.attributes.textColor
                      : undefined,
                }}
              >
                {story.attributes.description}
              </p>
            </div>

            <Button
              variant={variant === 'black' ? 'black' : 'white'}
              label="View Success Story"
              onClick={() =>
                router.push(REDIRECT_TO_SUCCESS_STORIES(story.attributes.url))
              }
            />
          </div>
        </div>
      )}

      {Math.floor(idx) % 2 === 0 && (
        <div className="flex items-center gap-[140px]">
          <div className="basis-1/2" data-aos="fade-right">
            <div className="mb-[20px] mt-[30px]">
              <Heading
                level={2}
                color={
                  story.attributes.textColor === 'white' ? 'white' : 'dark'
                }
                style={{
                  color:
                    !forHome && story.attributes.textColor
                      ? story.attributes.textColor
                      : undefined,
                }}
              >
                {story.attributes.title}
              </Heading>
            </div>
            <div className="mb-10">
              <p
                className={clsx(
                  'text-base sm:text-xl',
                  !story.attributes.textColor &&
                    variant === 'black' &&
                    'text-primary-700',
                  !story.attributes.textColor &&
                    variant === 'white' &&
                    'text-white'
                )}
                style={{
                  color:
                    !forHome && story.attributes.textColor
                      ? story.attributes.textColor
                      : undefined,
                }}
              >
                {story.attributes.description}
              </p>
            </div>

            <Button
              variant={variant === 'black' ? 'black' : 'white'}
              label="View Success Story"
              onClick={() =>
                router.push(REDIRECT_TO_SUCCESS_STORIES(story.attributes.url))
              }
            />
          </div>

          <div className="basis-1/2" data-aos="fade-left">
            <img
              src={getImage(story.attributes.story_image?.data?.attributes.url)}
              alt={
                story.attributes.story_image.data.attributes.alternativeText ??
                ''
              }
              className="aspect-[121/73] w-full rounded-lg object-cover object-center"
            />
          </div>
        </div>
      )}
    </>
  );
};
