import clsx from 'clsx';
import React from 'react';

type TagProps = {
  index: number;
  label: string;
};

export const Tag = ({ label, index }: TagProps) => {
  return (
    <div
      className={clsx(
        'my-1 rounded-2xl px-2.5 py-0.5',
        index % 2 !== 0 && 'bg-secondary-100',
        index % 2 === 0 && 'bg-secondary-200'
      )}
    >
      <p
        className={clsx(
          'text-center text-sm font-medium',
          index % 2 !== 0 && 'text-secondary-1000',
          index % 2 === 0 && 'text-secondary-1100'
        )}
      >
        {label}
      </p>
    </div>
  );
};
