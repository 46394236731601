import { useCallback, useRef, useState } from 'react';

import { Button } from '../Button';
import { CrossIcon, LinkIcon } from '../Icons';
import { iconLink } from './fileIcon';
import type { FileUploadProps } from './types';

export const FileUpload = ({
  name,
  files,
  supportedFormats,
  accept = '*',
  multiple = false,
  setFieldValue,
}: FileUploadProps) => {
  const [error, setError] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const supportedFiles: File[] = [];
      const unSupportedFiles: string[] = [];
      Object.values(event.target.files ?? {}).forEach((file) => {
        if (supportedFormats.includes(file.type)) {
          supportedFiles.push(file);
          setError('');
        } else unSupportedFiles.push(file.name);
      });

      if (unSupportedFiles.length > 0) {
        return setError(`${unSupportedFiles.join()} not supported`);
      }

      return setFieldValue(name, { ...supportedFiles });
    },
    []
  );

  const handleRemove = (file: File) => {
    setFieldValue(
      name,
      Object.values(files ?? {}).filter((f) => f !== file)
    );
  };

  return (
    <div>
      {files && (
        <div className="mb-5 flex flex-wrap gap-5">
          {Object.values(files).map((file, index) => (
            <div key={index} className="relative">
              <Button
                size="small"
                label={undefined}
                variant="text"
                startIcon={<CrossIcon className="h-2 w-2 text-white" />}
                className="absolute right-0 top-0 bg-black p-1"
                onClick={() => handleRemove(file)}
              />

              {file.type.includes('image') ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  className="h-20 w-20 object-center"
                />
              ) : (
                <img
                  src={iconLink(file.type)}
                  alt={file.name}
                  className="h-20 w-20 object-center"
                />
              )}
              <p className="w-20 break-words text-xs text-primary-700">
                {file.name}
              </p>
            </div>
          ))}
        </div>
      )}
      <div>
        <Button
          startIcon={<LinkIcon />}
          variant="text"
          type="button"
          className="text-sm font-semibold text-primary-600"
          label="Attach File"
          onClick={() => inputRef.current?.click()}
        />
        {error.length > 0 && (
          <p className="mt-2 text-xs text-red-700">{error}</p>
        )}

        <input
          type="file"
          name="files"
          hidden
          accept={accept}
          ref={inputRef}
          onChange={handleChange}
          multiple={multiple}
        />
      </div>
    </div>
  );
};
