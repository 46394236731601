import clsx from 'clsx';

import { classes } from './styles';
import type { IconProps } from './types';

export const ThunderBoltIcon = ({ className = '' }: IconProps) => (
  <svg
    width="100%"
    height="100%"
    className={clsx(classes.outline, className)}
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_149_47490)">
      <path
        d="M20.5001 5C20.0401 5 19.6667 4.62667 19.6667 4.16667V0.833333C19.6667 0.373333 20.0401 0 20.5001 0C20.9601 0 21.3334 0.373333 21.3334 0.833333V4.16667C21.3334 4.62667 20.9601 5 20.5001 5Z"
        fill="#151515"
      />
      <path
        d="M31.6968 9.63658C31.4835 9.63658 31.2701 9.55492 31.1068 9.39325C30.7818 9.06825 30.7818 8.53992 31.1068 8.21492L33.4635 5.85825C33.7885 5.53325 34.3168 5.53325 34.6418 5.85825C34.9668 6.18325 34.9668 6.71159 34.6418 7.03659L32.2851 9.39325C32.1235 9.55492 31.9101 9.63658 31.6968 9.63658Z"
        fill="#151515"
      />
      <path
        d="M39.6667 20.8334H36.3333C35.8733 20.8334 35.5 20.4601 35.5 20.0001C35.5 19.5401 35.8733 19.1667 36.3333 19.1667H39.6667C40.1267 19.1667 40.5 19.5401 40.5 20.0001C40.5 20.4601 40.1267 20.8334 39.6667 20.8334Z"
        fill="#151515"
      />
      <path
        d="M34.0535 34.3866C33.8401 34.3866 33.6268 34.3049 33.4635 34.1433L31.1068 31.7866C30.7818 31.4616 30.7818 30.9333 31.1068 30.6083C31.4318 30.2833 31.9601 30.2833 32.2851 30.6083L34.6418 32.9649C34.9668 33.2899 34.9668 33.8183 34.6418 34.1433C34.4801 34.3049 34.2668 34.3866 34.0535 34.3866Z"
        fill="#151515"
      />
      <path
        d="M20.5001 40C20.0401 40 19.6667 39.6267 19.6667 39.1667V35.8333C19.6667 35.3733 20.0401 35 20.5001 35C20.9601 35 21.3334 35.3733 21.3334 35.8333V39.1667C21.3334 39.6267 20.9601 40 20.5001 40Z"
        fill="#151515"
      />
      <path
        d="M6.94679 34.3866C6.73345 34.3866 6.52012 34.3049 6.35679 34.1433C6.03179 33.8183 6.03179 33.2899 6.35679 32.9649L8.71345 30.6083C9.03845 30.2833 9.56679 30.2833 9.89179 30.6083C10.2168 30.9333 10.2168 31.4616 9.89179 31.7866L7.53512 34.1433C7.37345 34.3049 7.16012 34.3866 6.94679 34.3866Z"
        fill="#151515"
      />
      <path
        d="M4.66667 20.8334H1.33333C0.873333 20.8334 0.5 20.4601 0.5 20.0001C0.5 19.5401 0.873333 19.1667 1.33333 19.1667H4.66667C5.12667 19.1667 5.5 19.5401 5.5 20.0001C5.5 20.4601 5.12667 20.8334 4.66667 20.8334Z"
        fill="#151515"
      />
      <path
        d="M9.30325 9.63658C9.08992 9.63658 8.87659 9.55492 8.71325 9.39325L6.35825 7.03659C6.03325 6.71159 6.03325 6.18325 6.35825 5.85825C6.68325 5.53325 7.21159 5.53325 7.53659 5.85825L9.89325 8.21492C10.2183 8.53992 10.2183 9.06825 9.89325 9.39325C9.72992 9.55492 9.51659 9.63658 9.30325 9.63658Z"
        fill="#151515"
      />
      <path
        d="M18.1217 33.3334C18.0234 33.3334 17.9267 33.3167 17.83 33.2817C17.465 33.1451 17.2434 32.7751 17.295 32.3917L18.7167 21.6667H13C12.685 21.6667 12.3967 21.4884 12.255 21.2067C12.1134 20.9251 12.1434 20.5867 12.3334 20.3334L22.3334 7.00007C22.565 6.69007 22.9767 6.58007 23.3284 6.73341C23.6817 6.88507 23.8867 7.25674 23.8234 7.63674L22.3167 16.6667H28C28.3067 16.6667 28.5884 16.8351 28.7334 17.1051C28.8784 17.3751 28.8634 17.7017 28.6967 17.9601L18.8184 32.9601C18.66 33.1984 18.395 33.3334 18.1217 33.3334ZM14.6667 20.0001H19.6667C19.9084 20.0001 20.135 20.1051 20.2934 20.2851C20.4517 20.4634 20.525 20.7051 20.4934 20.9434L19.425 29.0067L26.4534 18.3334H21.3334C21.0884 18.3334 20.8567 18.2267 20.6967 18.0384C20.5384 17.8517 20.47 17.6034 20.5117 17.3634L21.6167 10.7334L14.6667 20.0001Z"
        fill="#151515"
      />
    </g>
    <defs>
      <clipPath id="clip0_149_47490">
        <rect width="40" height="40" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
