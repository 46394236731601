import '../styles/global.css';
import 'aos/dist/aos.css';

import AOS from 'aos';
import type { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import { useEffect } from 'react';

import { getFooter } from '@/API/footerAPI';
import { getMenus } from '@/API/MenuAPI';
import MainLayout from '@/layouts/MainLayout';
import type { FooterType } from '@/types/footer';
import type { MenuElementType } from '@/types/menu';
import GoogleAnalytics from '@/utils/GoogleAnalytics';

const MyApp = ({
  Component,
  pageProps,
  menus,
  footer,
}: AppProps & { menus: MenuElementType[]; footer: FooterType }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <MainLayout menus={menus} footer={footer}>
      <>
        <div className="pt-[96px] sm:pt-containerTopGap">
          <DefaultSeo titleTemplate="%s" defaultTitle="Dhrubok" />
          <Component {...pageProps} />
        </div>
        {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ? (
          <GoogleAnalytics ga_id={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS} />
        ) : null}
      </>
    </MainLayout>
  );
};

MyApp.getInitialProps = async () => {
  const [data, footerData] = await Promise.all([
    getMenus({
      params: {
        populate: 'menu_elements,menu_elements.menuitems',
      },
    }),
    getFooter({
      params: {
        populate:
          'navigationPage,navigationServices,socialIcons,socialIcons.icon,achievements,achievements.image',
      },
    }),
  ]);

  return {
    menus: data.data?.attributes.menu_elements.data || null,
    footer: footerData.data || null,
  };
};

export default MyApp;
