import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

import { Heading } from '@/components/Heading';
import { REDIRECT_TO_SUCCESS_STORIES } from '@/constants/variables';
import getImage from '@/utils/getImage';

import { Button } from '../../Button';
import type { StoryCardProps } from '../types';

export const StoryCardMobile = ({
  story,
  variant,
  forHome,
}: Omit<StoryCardProps, 'idx'>) => {
  const router = useRouter();

  return (
    <div>
      <img
        src={getImage(story.attributes.story_image?.data?.attributes.url)}
        alt={story.attributes.story_image.data.attributes.alternativeText ?? ''}
        className="aspect-[121/73] w-full object-cover object-center"
      />
      <div className="mt-5">
        {/* <img
          className="h-[30px] max-w-[107px] object-cover object-center"
          src={story.thumbImg}
          alt="logo"
        /> */}
        <Heading
          level={2}
          color={story.attributes.textColor === 'white' ? 'white' : 'dark'}
          style={{
            color:
              !forHome && story.attributes.textColor
                ? story.attributes.textColor
                : undefined,
          }}
        >
          {story.attributes.title}
        </Heading>
        <div className="mb-5">
          <p
            className={clsx(
              'text-base',
              !story.attributes.textColor &&
                variant === 'black' &&
                'text-primary-700',
              !story.attributes.textColor && variant === 'white' && 'text-white'
            )}
            style={{
              color:
                !forHome && story.attributes.textColor
                  ? story.attributes.textColor
                  : undefined,
            }}
          >
            {story.attributes.description}
          </p>
        </div>

        <Button
          variant={variant === 'black' ? 'black' : 'white'}
          label="View Success Story"
          size="small"
          onClick={() =>
            router.push(REDIRECT_TO_SUCCESS_STORIES(story.attributes.url))
          }
        />
      </div>
    </div>
  );
};
