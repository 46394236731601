export const classes = {
  fontWeight: {
    bold: 'font-bold',
    semibold: 'font-semibold',
    normal: 'font-medium',
  },
  color: {
    dark: 'text-primary-900',
    mediumDark: 'text-primary-800',
    lightDark: 'text-primary-700',
    white: 'text-white',
    noStyle: '',
  },
  level: {
    0: '',
    0.5: 'sm:text-6xl-lh-fs text-4xl',
    1: 'sm:text-6xl-72lh text-4xl !font-titleFont font-bold',
    2: 'sm:text-[2.5rem] text-3xl !leading-snug !font-titleFont !font-bold',
    2.5: 'sm:text-[2rem] !leading-snug text-2xl !font-titleFont',
    3: 'sm:text-3xl-lh text-xl !font-titleFont',
    4: 'sm:text-2xl text-lg !font-titleFont',
    5: 'text-xl-lh !font-titleFont',
    6: 'text-lg !font-titleFont',
  },
};
