import clsx from 'clsx';

import { classes } from './styles';
import type { IconProps } from './types';

export const CrossIcon = ({ className = '' }: IconProps) => (
  <svg
    className={clsx(classes.outline, className)}
    width="100%"
    height="100%"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.721268 21.2786C0.790923 21.3483 0.873639 21.4037 0.964688 21.4414C1.05574 21.4791 1.15333 21.4986 1.25189 21.4986C1.35045 21.4986 1.44805 21.4791 1.5391 21.4414C1.63015 21.4037 1.71286 21.3483 1.78252 21.2786L11 12.0611L20.2213 21.2786C20.362 21.4193 20.5529 21.4984 20.7519 21.4984C20.9509 21.4984 21.1418 21.4193 21.2825 21.2786C21.4232 21.1379 21.5023 20.947 21.5023 20.748C21.5023 20.549 21.4232 20.3581 21.2825 20.2174L12.0613 10.9999L21.2788 1.7786C21.4195 1.63787 21.4986 1.447 21.4986 1.24798C21.4986 1.04895 21.4195 0.858081 21.2788 0.717351C21.138 0.57662 20.9472 0.497559 20.7481 0.497559C20.5491 0.497559 20.3582 0.57662 20.2175 0.717351L11 9.9386L1.77877 0.721101C1.63529 0.598231 1.45074 0.534026 1.26198 0.541317C1.07322 0.548608 0.894167 0.626857 0.760596 0.760428C0.627025 0.893999 0.548776 1.07305 0.541485 1.26181C0.534194 1.45057 0.598399 1.63512 0.721268 1.7786L9.93877 10.9999L0.721268 20.2211C0.58158 20.3616 0.503174 20.5517 0.503174 20.7499C0.503174 20.948 0.58158 21.1381 0.721268 21.2786Z"
      fill="black"
    />
  </svg>
);
