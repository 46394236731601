import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

import { Button } from '../Button';
import { Description } from '../Description';
import { Heading } from '../Heading';
import { ArrowTopRightIcon } from '../Icons';
import { ScreenResizer } from '../ScreenResizer';
import type { MainCardProps } from './types';

export const MainCard = ({ data, i, withButton = true }: MainCardProps) => {
  const router = useRouter();
  return (
    <div
      // className={`h-full rounded-lg bg-customPrimary py-6 px-9 text-center ${
      //   colSpan && i === 3 ? 'col-span-3' : ''
      // }`}
      className={clsx(
        'h-full rounded-lg bg-customPrimary text-center',
        data?.featured && 'sm:col-span-3',
        withButton && 'px-9 py-6',
        !withButton && 'p-10'
      )}
      key={i}
    >
      <div className="mx-auto mb-5 flex h-[80px] w-[80px] items-center justify-center rounded-[50%] bg-siteBg p-4 sm:h-[72px] sm:w-[72px]">
        <img src={data?.thumb} alt={data?.title} />
      </div>
      <Heading level={4} color="white" className="mb-3">
        {data?.title}
      </Heading>

      <p
        className={`mx-auto mb-6 h-[112px] text-base leading-7 text-textColorLight sm:text-lg ${
          data?.featured ? 'sm:w-[660px]' : ''
        }`}
      >
        <Description wordLimit={20}>{data?.subtitle}</Description>
      </p>
      {withButton && (
        <div>
          <ScreenResizer displayOnly="desktop">
            <Button
              label="Learn More"
              onClick={() => router.push(`services/${data.link}` ?? '/')}
              variant="text"
              className="font-semibold leading-6 text-white"
              endIcon={<ArrowTopRightIcon className="h-6 w-6" />}
            />
          </ScreenResizer>
          <ScreenResizer displayOnly="mobile">
            <Button
              onClick={() => router.push(`services/${data.link}` ?? '/')}
              label="Learn More"
              variant="outlined"
              size="xs"
            />
          </ScreenResizer>
        </div>
      )}
    </div>
  );
};
