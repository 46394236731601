import clsx from 'clsx';
import React, { useCallback, useState } from 'react';

import type { CollapseProps } from './types';

export const Collapse = ({
  collapsible,
  children,
  iconAfter,
  iconBefore,
  collapsibleClassName = '',
  contentClassName = '',
}: CollapseProps) => {
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => setOpen(!open), [open]);

  return (
    <div>
      <div
        className={clsx(
          'flex w-full cursor-pointer justify-between',
          collapsibleClassName
        )}
        onClick={handleToggle}
      >
        {collapsible}
        {iconBefore && !open && iconBefore}
        {iconAfter && open && iconAfter}
      </div>

      <div
        className={clsx(
          contentClassName,
          'transition-all',
          !open && 'invisible -translate-y-full',
          open && 'visible translate-y-0'
        )}
      >
        {open && children}
      </div>
    </div>
  );
};
